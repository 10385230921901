import {doc, getDoc, setDoc} from "firebase/firestore";
import {LicenseApplicationForm} from "@/models/LicenseApplicationForm";
import {Property} from "@/models/Property";
import {License} from "@/models/License";

export class LicenseContact {

  constructor(data) {
    this.owners = [];
    this.address = '';
    this.email = '';
    this.phone = '';
    this.parse(data);
  }

  get isComplete() {
    return !!this.name;
  }

  get name() {
    return this.owners.join('\n');
  }

  set name(name) {
    this.owners = name.split(/\n/g);
  }

  parse(data) {
    data = data || {};
    this.owners = data.owners || [];
    this.address = data.address || '';
    this.email = data.email || '';
    this.phone = data.phone || '';
  }

  serialize() {
    return {
      owners: this.owners || [],
      address: this.address || null,
      email: this.email || null,
      phone: this.phone || null
    };
  }
}

export class LicenseEditorModel {

  constructor(city, data, id) {
    this.city = city;
    this.id = id;
    this.form = new LicenseApplicationForm(city);
    this.loading = false;
    this.generatingLicenseId = false;
    this.submitting = false;

    // Owner info
    this.contact = new LicenseContact();

    this.parse(data);
  }

  get isNew() {
    return !this.id;
  }

  get parcelId() {
    return this._property && this._property.id;
  }

  get cityId() {
    return this.city && this.city.id;
  }

  get propertyId() {
    return this._propertyId;
  }

  set propertyId(propertyId) {
    this._propertyId = propertyId;
    this.findMatchingProperty();
  }

  async findMatchingProperty() {
    this.loading = true;
    try {
      this.property = this._propertyId ? (await this.city.getProperty(this._propertyId)) : null;
    } finally {
      this.loading = false;
    }
  }

  get property() {
    return this._property;
  }

  set property(property) {
    this._property = property;
    if (this.contact.owners.length === 0) {
      this.contact.owners = (property && property.owners) || [];
    }
    if (!this.contact.address) {
      this.contact.address = property && property.details && property.details.ownerAddress;
    }
  }

  get addressFormatted() {
    return this.property.addressFormatted;
  }

  get customFields() {
    return this.form.fields;
  }

  getCustomFieldValue(id) {
    const fv = this.customFieldValues.filter(fv => fv.id === id)[0];
    return (fv && fv.value) || null;
  }

  setCustomFieldValue(id, value) {
    let values = this.customFieldValues.filter(fv => fv.id !== id);
    values.push({id, value});
    this.customFieldValues = values;
  }

  get isAddressComplete() {
    return !!this.propertyId;
  }

  get isComplete() {
    if (!this.propertyId || !this.contact.isComplete || (!this.id && !this.licenseId)) {
      return false;
    }

    const values = {};
    this.customFieldValues.forEach(fv => values[fv.id] = fv.value);
    for (let field of this.form.fields) {
      if (field.required && !values[field.id]) {
        return false;
      }
    }
    return true;
  }

  indexKeys() {
    const contact = (this.contact || {}).name || '';
    return (this.addressFormatted + ' ' + this.id + ' ' + contact)
    .split(/ /g)
    .map(word => word.trim())
    .filter(word => !!word);
  }

  parse(data) {
    data = {...data};
    this._propertyId = data.propertyId;
    this._property = data.property ? Property.dataConverter(this.city)(data.property) : null;
    this._property = null;
    this.contact = new LicenseContact(data.contact)

    // Listings
    this.listings = data.listings || [];

    // Custom Fields
    this.customFieldValues = data.customFieldValues || [];

    // Admin fields
    this.dateApplied = data.dateApplied || new Date();
    this.status = data.status || this.city.defaultLicenseStatus;
    this.paid = data.paid || false;
    this.licenseId = data.licenseId || null;
    this.dateIssued = data.dateIssued || null;
  }

  serialize() {
    let data = {
      propertyId: this._propertyId,
      contact: this.contact.serialize(),
      listings: this.listings,
      customFieldValues: this.customFieldValues,
      dateApplied: this.dateApplied,
      status: this.status,
      paid: this.paid,
      licenseId: this.licenseId,
      dateIssued: this.dateIssued
    };
    if (this._property?.id === this._propertyId) {
      data.property = this._property?._data;
    }
    return data;
  }

  async submit() {
    this.submitting = true;
    try {
      if (this.isNew) {
        if (!this.licenseId) {
          await this.generateLicenseId();
        }
        if (await this.licenseExists(this.licenseId)) {
          throw {type: 'LICENSE_EXISTS'};
        }
        this.id = this.licenseId;
      }
      await setDoc(doc(this.collection, this.id), this.serialize(), {merge:true});
      return await License.one(this.city, this.id);
    } finally {
      this.submitting = false;
    }
  }

  get collection() {
    return this.city.subCollection('licenses');
  }

  async licenseExists(licenseId) {
    let snapshot = await getDoc(doc(this.collection, licenseId));
    return snapshot.exists();
  }

  async generateLicenseId() {
    this.generatingLicenseId = true;
    try {
      for (let i = 0; i < 5; i++) {
        let n = Math.ceil(Math.random() * 999999).toString();
        while (n.length < 6) {
          n = `0${n}`;
        }
        console.log(`Checking if license ${n} exists`);
        if (!(await this.licenseExists(n))) {
          this.licenseId = n;
          return;
        }
      }
      throw {type: 'COULD_NOT_FIND_UNUSED_ID'};
    } finally {
      this.generatingLicenseId = false;
    }
  }
}
