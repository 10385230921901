<template>
  <div class="assessor-link">
    <a v-if="!!property"
      :href="property.url"
       target="_blank"
       title="Open the assessor record in a new tab"
       class="d-flex flex-row align-items-center gap-1"
    >
      <i v-if="!useEndIcon" class="bi-file-earmark-pdf" />
      <div>Parcel #{{ property.id }}</div>
      <i v-if="useEndIcon" class="bi-box-arrow-up-right small" style="font-size:0.8em" />
    </a>
    <div v-else class="text-muted small">
      (Unverified Address)
    </div>
  </div>
</template>

<script>
import {Property} from "@/models/Property";

export default {
  props: {
    property: Property,
    useEndIcon: Boolean
  }
}
</script>

<style>
.assessor-link > a {
  text-decoration: none;
  color: inherit;
}
.assessor-link > a:hover {
  text-decoration: underline;
}
</style>
