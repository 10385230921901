<template>

  <!-- Mobile city header -->
  <div class="d-block d-lg-none" v-if="model.cityInfo">
    <CityButton :city="model.cityInfo" center class="p-2" />
  </div>

  <!-- Top Bar -->
  <nav class="navbar navbar-expand-lg border-bottom">
    <div class="container-fluid flex-nowrap">
      <!-- City icon/label -->
      <a class="text-nowrap btn btn-flat border me-2 overflow-auto" href="#sidebar" data-bs-toggle="offcanvas" role="button">
        <CityButton :city="model.cityInfo" inline invert-colors class="bg-transparent d-none d-md-flex">
          <div class="d-inline-block dropdown-toggle pe-1">
            <i class="bi-chevron-right small mx-1"/>&nbsp;{{ getTabTitle(selectedTab) }}&nbsp;
          </div>
        </CityButton>
        <div class="d-md-none dropdown-toggle pe-1 fw-bold">
          {{ getTabTitle(selectedTab) }}
        </div>
      </a>

      <ul class="me-2 navbar-nav d-flex flex-row" v-if="buttons && buttons.length">
        <li
          :class="`nav-item text-nowrap ${button.showOnMobile ? '' : 'd-none d-lg-block'} ${i === 0 ? '' : 'ms-2'}`"
          v-for="(button, i) in buttons"
          :key="button.id"
          :id="button.id">
          <a v-if="button.click" href="#" @click.prevent="button.click" :class="`btn ${button.text ? '' : 'btn-icon'} btn-${button.variant || 'flat'}`" :title="button.tooltip">
            <i v-if="button.icon" :class="`bi-${button.icon}`" />
            <span v-if="button.text" class="d-none d-md-inline-block ms-2">{{ button.text }}</span>
          </a>
          <a v-else :href="button.href" :download="button.filename" target="_blank" :disabled="button.disabled" :class="`btn ${button.disabled ? 'btn-disabled' : ''} ${button.text ? '' : 'btn-icon'} btn-${button.variant || 'flat'}`" :title="button.tooltip">
            <i v-if="button.icon" :class="`bi-${button.icon}`" />
            <span v-if="button.text" class="d-none d-md-inline-block ms-2">{{ button.text }}</span>
          </a>
        </li>
      </ul>

      <div class="flex-grow-1 d-none d-lg-flex flex-row align-items-center position-relative me-2">
        <template v-if="showSearch">
          <input
            class="form-control"
            type="text"
            :placeholder="searchPlaceholder || 'Search'" v-model="query"
            @keydown.enter="search"
          />
          <div v-if="query" class="position-absolute" style="right: 0">
            <button type="button" class="btn btn-flat" @click="search">
              <i class="bi-search"/>
            </button>
            <button type="button" class="btn btn-flat" @click="clear">
              <i class="bi-x-lg" />
            </button>
          </div>
        </template>
      </div>
      <div class="d-block d-lg-none flex-grow-1"/>

      <ul class="navbar-nav" v-if="model.isAuthenticated">
        <UserMenu :model="model" />
      </ul>
    </div>
  </nav>

  <!-- Side Bar -->
  <div :class="`offcanvas offcanvas-start ${$route.query.login ? 'show' : ''}`" id="sidebar" ref="sidebar">
    <div class="offcanvas-header">
      <h5 class="offcanvas-title">
        <CityButton v-if="model.cityInfo" class="nav-link small" :city="model.cityInfo" invert-colors @click="$router.push({name:'city'})">
          <div class="fw-normal" style="font-size:0.9rem;">Short-Term Rental Portal</div>
        </CityButton>
        <div v-else class="d-flex flex-row align-items-center">
          <SpinnerView small /> <div class="ms-2 fw-normal">Loading</div>
        </div>
      </h5>
      <button type="button" class="mx-1 btn-close btn-flat" data-bs-dismiss="offcanvas" aria-label="Close" ref="sidebarCloseButton"/>
    </div>
    <div class="offcanvas-body border-top d-flex flex-column p-0" ref="sidebarBody">
      <div v-if="!model.isAuthenticated" class="m-3">
        <p class="alert alert-secondary">
          If you have a staff account, you may login to access additional features.
        </p>
        <LoginForm :model="model" />
      </div>
      <ul class="list-group list-group-flush flex-column flex-grow-1 mt-2">
        <li
          v-for="tab in tabs"
          :key="tab.id"
          :class="`list-group-item border-0 p-0 list-group-item-action ${selectedTab === tab.id ? 'text-primary fw-bold' : ''}`">
          <router-link
            :to="{name:tab.name || tab.id}"
            class="nav-link mb-1 d-flex flex-row align-items-center px-3 py-2"
            @click="closeSidebar">
            <div class="flex-grow-1">
              <i :class="`bi-${tab.icon} me-1`" /> {{ tab.label }}
            </div>
            <div v-if="tab.badge" class="small">
              {{ formatNumber(tab.badge) }}
            </div>
          </router-link>
        </li>
      </ul>
      <CityList
        @select="closeSidebar"
        class="border-top py-2"
      />
      <ul class="list-group list-group-flush border-top py-2" v-if="model.isAuthenticated">
        <li class="list-group-item border-0 p-0 list-group-item-action">
          <a href="#" @click.prevent="() => {closeSidebar(); LocalStorage.set('tourComplete', false);}" class="nav-link mb-1 d-flex flex-row align-items-center px-3 py-2">
            <div class="flex-grow-1">
              <i class="bi-question-circle me-1"/> Help
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {AppModel} from "@/models/AppModel";
import LoginForm from "@/components/LoginForm";
import CityButton from "@/components/common/CityButton";
import UserMenu from "@/components/common/UserMenu";
import CityList from "@/components/common/CityList.vue";
import SpinnerView from "@/components/common/SpinnerView.vue";

export default {
  components: {
    SpinnerView,
    CityList,
    UserMenu,
    LoginForm,
    CityButton
  },
  emits: ['search'],
  props: {
    selectedTab: String,
    model: AppModel,
    buttons: Array,
    showSearch: Boolean,
    searchPlaceholder: String,
    extraTabs: Array
  },
  data() {
    return {
      query: ''
    };
  },
  mounted() {
    this.$refs.sidebar.addEventListener('hidden.bs.offcanvas', () => {
      this.$refs.sidebarBody.scrollTop = 0;
    });
  },
  computed: {
    tabs() {
      if (this.model.isAuthenticated) {
        return [
          {
            id: 'home',
            label: 'Home',
            icon: 'house',
            name: 'city'
          },
          {
            id: 'licenses',
            label: 'Licenses',
            icon: 'file-earmark-check',
            badge: this.model.activeLicenseCount,
          },
          {
            id: 'violations',
            label: 'Violations',
            badge: this.model.confirmedViolationCount,
            icon: 'exclamation-octagon'
          },
          {
            id: 'listings',
            label: 'Needs Attention',
            badge: this.model.potentialViolationCount,
            icon: 'geo-alt'
          }
        ];
      }
      return [];
    }
  },
  methods: {
    search() {
      this.$emit('search', this.query);
    },
    clear() {
      this.query = '';
      this.search();
    },
    getTabTitle(tab) {
      return tab === 'listings' ? 'Needs Attention' : tab === 'violations' ? 'Violations' : 'Licenses';
    },
    closeSidebar() {
      this.$refs.sidebarCloseButton.click();
    }
  }
}
</script>

<style>
.dropdown.no-caret .dropdown-toggle::after {
  display: none;
}
</style>
