<template>
  <div class="position-relative rounded">
    <b-image
      v-if="imageUrls.length > 0"
      :src="imageUrl"
      auto-size
      style="cursor: pointer"
      image-class="rounded w-100"
    />
    <div v-if="imageUrls.length > 1" class="d-flex flex-row align-items-center justify-content-between px-1 position-absolute top-0 start-0 end-0 bottom-0">
      <div class="bg-white bg-opacity-75 rounded">
        <icon-button small icon="chevron-left" @click.stop="displayIndex = (displayIndex - 1) % imageUrls.length"/>
      </div>
      <div class="bg-white bg-opacity-75 rounded">
        <icon-button small icon="chevron-right" @click.stop="displayIndex = (displayIndex + 1) % imageUrls.length"/>
      </div>
    </div>
    <div
      v-if="imageUrls.length > 1"
      class="position-absolute bottom-0 mb-2 px-1 start-0 end-0 d-flex flex-row justify-content-center"
    >
      <div class="d-flex flex-row gap-2 rounded rounded-pill py-1 px-2 bg-black bg-opacity-25 flex-wrap">
        <div
          v-for="(url, index) in imageUrls"
          :key="index"
          :class="['bg-white', {'bg-opacity-50':index !== displayIndex}]"
          style="width:8px;height:8px;border-radius:4px"
          @click="displayIndex = index"/>
      </div>
    </div>
    <div v-if="$slots['upper-right']" class="position-absolute m-1 top-0 end-0">
      <slot name="upper-right" />
    </div>
  </div>
</template>

<script>
import BImage from "@/components/common/BImage.vue";
import IconButton from "@/components/common/IconButton.vue";

export default {
  components: {IconButton, BImage},
  props: {
    imageUrls: Array
  },
  emits: ['update:displayIndex'],
  data() {
    return {
      displayIndex: 0
    };
  },
  computed: {
    imageUrl() {
      return this.imageUrls[this.displayIndex];
    }
  }
}
</script>
